.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    max-width: 395px;
}

.h2 {
    margin: 22px 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.67;
    letter-spacing: normal;
    color: var(--black-700);
}

.text {
    margin: 0 10px;
    max-width: 466px;
    display: inline-block;
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-700);
    margin-bottom: 10px;
}

.otherText {
    margin: 10px 10px 0;
    max-width: 466px;
    display: inline-block;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-700);
}

.button {
    margin-top: 20px;
}

.loginArea {
    background-color: var(--whitebase);
    margin-top: 14px;
    padding-top: 20px;
    padding-bottom: 26px;
    width: 100%;
}

.inner {
    width: 100%;
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.inputUser {
    margin-top: 19px;
}

.inputPass {
    margin-top: 19px;
}

/* --tablet-widths */
@media (min-width: 660px) {
}

/* --desktop-widths */
@media (min-width: 1024px) {
    .root {
        margin-bottom: 173px;
    }

    .button {
        margin-top: 20px;
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
}
