.root {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    background-color: var(--tertiary-050);
    background-size: auto 100%;
    background-image: url(./parts/img/bg-phagans.png);
}

.wrapper {
    background-color: var(--whitebase-70);
    border-radius: 7px;
}

.root > footer {
    margin-top: auto;
}

@media (min-width: 1024px) {
    .root {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        min-height: 100vh;
    }
    .wrapper {
        width: 860px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin-top: 90px;
        margin-bottom: 90px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    }
}
